#section-2 {
  display: flex;
  justify-content: center;
  background: #72ccd3 url("../images/section-2-bg.svg") no-repeat center bottom;
}
#section-2 .section-in {
  padding-top: 7vh;
  height: auto;
  width: 95vw;
  padding-bottom: 7vh;
  text-shadow: 2px 2px 2px #36beba;
  color: white;
}
#section-2 .section-in .top {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 7vh;
}
#section-2 .section-in .right {
  padding-left: 5rem;
}
#section-2 .section-in .right .first {
  padding-bottom: 5rem;
}
#section-2 .section-in .right .second {
  border-top: 1px solid white;
  padding-top: 5rem;
}
#section-2 .section-in .certificate {
  max-width: 300px;
  height: 400px;
}
#section-2 h2 {
  font-size: 3rem;
}
#section-2 h1 {
  font: 4rem/1.2 "Montserrat" !important;
}
#section-2 h3 {
  font-size: 2.5rem;
  color: white;
}
#section-2 h4 {
  font-size: 3rem;
  padding-bottom: 0.5rem;
}
#section-2 h5 {
  font-size: 2.5rem;
}

@media all and (max-width: 1024px) {
  #section-2 .section-in .top {
    width: 100%;
    margin-bottom: 3vh;
  }
  #section-2 .section-in .right {
    padding-left: 3rem;
  }
}
@media all and (max-width: 768px) {
  #section-4 .section-in > div {
    width: calc(70% / 1);
    height: auto;
    margin-left: 15%;
  }
  #section-1 .section-in h1 {
    font-size: calc(16px + 162%);
  }
  #section-1 .section-in {
    width: 90vw;
  }
  #section-1 .section-in button {
    width: 47%;
    margin: 0.6vh 0.6vh;
    padding: 1vh 0vh;
    background-image: none;
    font-size: calc(16px + 2%);
  }
}

@media all and (max-width: 640px) {
  #section-2 .section-in .top {
    align-items: baseline;
    flex-direction: column;
  }
  #section-2 .section-in .right {
    margin-top: 3vh;
    padding-left: 0;
  }
  #section-2 h2 {
    font-size: 2.5rem;
  }
  #section-2 h1 {
    font: 3rem "Montserrat" !important;
  }
  #section-2 h3 {
    font-size: 2.3rem;
    color: white;
  }
  #section-2 h4 {
    font-size: 2.7rem;
  }
  #section-2 h5 {
    font-size: 2.4rem;
  }
}
@media all and (max-width: 420px) {
  #section-2 .section-in .top {
    align-items: baseline;
    flex-direction: column;
  }
  #section-2 .section-in .right {
    margin-top: 3vh;
    padding-left: 0;
  }
  #section-2 h4 {
    font-size: 2.3rem;
  }
  #section-2 h5 {
    font-size: 2rem;
  }
}
