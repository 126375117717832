.fbanner {
  display: flex;
  justify-content: center;
  background: var(--footer-bg);
}
.fbanner-in {
  padding: 5vh 0;
  width: 100%;
  height: 100%;
  max-width: 1400px;
}
.fbanner-box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.fbanner img {
  width: 100%;
  height: 100%;
  padding: 0 22%;
}
