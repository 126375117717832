@charset "utf-8";
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/variable/pretendardvariable.css");
@import url("https://webfontworld.github.io/NanumSquareNeo/NanumSquareNeo.css");

* {
  margin: 0;
  padding: 0;
  font: inherit;
  color: inherit;
}
*,
:after,
:before {
  box-sizing: border-box;
}
:root {
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  cursor: default;
  line-height: 1.5;
  overflow-wrap: break-word;
  word-break: break-word;
  tab-size: 4;
}
html,
body {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  /* overflow-x: hidden; */
}
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
button {
  background: none;
  border: 0;
  cursor: pointer;
}
ul,
li,
ol {
  list-style: none;
}
a {
  text-decoration: none;
  color: inherit;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* font */

html,
body,
button,
input,
select {
  font-family: "NanumSquareNeo", "Pretendard Variable", Pretendard,
    -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue",
    "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic",
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  color: #333;
  font-weight: 400;
  font-size: 10px;
  line-height: 1;
}
@media (max-width: 1400px) {
  html,
  body,
  button,
  input,
  select {
    font-size: 9px;
  }
}
@media (max-width: 1190px) {
  html,
  body,
  button,
  input,
  select {
    font-size: 10px;
  }
}
