@charset "utf-8";

/* color */
:root {
  --main-color: #34beba;
  --white-color: #ffffff;
  --text-color: #333333;
  --footer-bg: #f0f0f0;
  --footer-text: #999999;
  --gra-text: linear-gradient(0.4turn, #34beba, #6880ff);
  --gra-bg: linear-gradient(#34beba, #ffffff);
}

/* 마진 */
.mb30 {
  margin-bottom: 3rem;
}
.mb40 {
  margin-bottom: 4rem;
}
.mb80 {
  margin-bottom: 8rem;
}
.mb100 {
  margin-bottom: 10rem;
}
@media (max-width: 1190px) {
  .mb40 {
    margin-bottom: 2.6rem;
  }
  .mb80 {
    margin-bottom: 4rem;
  }
  .mb100 {
    margin-bottom: 4.8rem;
  }
}
@media (max-width: 400px) {
  .mb40 {
    margin-bottom: 1.6rem;
  }
  .mb80 {
    margin-bottom: 2.4rem;
  }
}

/* 공통 */
.width140 {
  max-width: 140rem;
  width: 100%;
  margin: 0 auto;
}
.flex {
  display: flex;
}
.alignC {
  align-items: center;
}
.justify {
  justify-content: space-between;
}
.relative {
  position: relative;
}
.m_only {
  display: none;
}
@media (max-width: 1190px) {
  .pc_only {
    display: none;
  }
  .m_only {
    display: block;
  }
}

/* header */
header {
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 10.8rem;
  padding: 2.8rem 0;
  background: rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid var(--white-color);
}
.header_inner > button {
  font-size: 2rem;
  font-weight: 800;
  color: var(--white-color);
  background: var(--main-color);
  padding: 1.6rem 0;
  width: 14.8rem;
  border-radius: 30px;
}
@media (max-width: 1400px) {
  header {
    padding: 2.8rem 2rem;
  }
}
@media (max-width: 1190px) {
  header {
    padding: 1.8rem 2rem;
    height: 8.8rem;
  }
  .logo {
    max-width: 11.8rem;
  }
  .header_inner > button {
    font-size: 1.6rem;
    width: 12.8rem;
  }
}

/* section */
section {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}
video {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
  object-fit: cover;
}
.sec01_inner .left {
  padding-top: 26rem;
}
.left > h1 {
  font-size: 7.8rem;
  font-weight: 900;
  line-height: 1.5;
  color: var(--white-color);
}
.left > p {
  font-size: 3.6rem;
  font-weight: 700;
  color: var(--white-color);
}
.sec01_inner .btns {
  gap: 1.6rem;
}
.apple_btn {
  font-size: 2.4rem;
  font-weight: 800;
  color: var(--white-color);
  gap: 0.8rem;
  padding: 1.6rem 2.4rem;
  background: var(--main-color);
  border-radius: 30px;
}
.google_btn {
  font-size: 2.4rem;
  font-weight: 800;
  color: var(--white-color);
  gap: 0.8rem;
  padding: 1.6rem 2.4rem;
  border: 1px solid var(--white-color);
  border-radius: 30px;
}
.clean_btn {
  font-size: 2.4rem;
  font-weight: 800;
  margin-top: 1.2rem;
  color: var(--white-color);
  gap: 0.8rem;
  padding: 1.6rem 6.5rem;
  background: #9f48f4;
  border-radius: 30px;
}
.sec01_img {
  position: absolute;
  right: 0;
  top: 20rem;
}

section.bg {
  background: var(--main-color);
}
.sec02_inner {
  padding-top: 44.4rem;
}
.sec02_img {
  position: absolute;
  right: 0;
  top: 16.4rem;
}

.sec03_inner {
  padding-top: 12.7rem;
}
.inner_flex .right > p {
  font-size: 3.6rem;
  font-weight: 700;
  color: var(--white-color);
  text-align: left;
}
.rolling_wrap {
  position: relative;
  width: 56rem;
  height: 4.4rem;
  box-sizing: border-box;
  overflow: hidden;
}
.rolling_wrap li {
  position: absolute;
  top: -50px;
  left: 0;
  font-size: 4.4rem;
  font-weight: 900;
  color: var(--white-color);
  text-align: left;
}
.rolling_wrap li.prev {
  top: 50px;
  transition: top 0.5s ease;
}
.rolling_wrap li.current {
  top: 0;
  transition: top 0.5s ease;
}
.rolling_wrap li.next {
  top: -50px;
}
.sec03_img {
  position: absolute;
  right: 0;
  top: 43.7rem;
}

.sec04_inner {
  padding-top: 41.2rem;
}
.left .s_h1 {
  font-size: 6rem;
}
.sec04_img {
  position: absolute;
  right: 0;
  top: 15.3rem;
}

.sec05_inner {
  padding-top: 15rem;
}
.sec05_img {
  position: absolute;
  right: 0;
  top: 27.9rem;
}

.sec06 {
  background: var(--gra-bg);
  overflow: visible;
}
.sec06_inner {
  padding-top: 16rem;
}
.sec06_img {
  position: absolute;
  right: 0;
  top: 29.4rem;
}

.sec07 {
  background: var(--white-color);
}
.gra_txt {
  background: var(--gra-text);
  color: transparent !important;
  -webkit-background-clip: text;
  background-clip: text;
}
.sec07_inner {
  padding-top: 23.6rem;
}
.icon_wrap {
  gap: 3.2rem;
}
.icon_txt_wrap > p {
  font-size: 3.6rem;
  font-weight: 700;
}
.icon_txt_wrap > h3 {
  font-size: 6rem;
  font-weight: 900;
}
@media (max-width: 1400px) {
  section {
    padding: 0 2rem;
  }
  .sec01_inner .left {
    padding-top: 30rem;
  }
  .sec02_inner {
    padding-top: 48rem;
  }
  .sec03_inner {
    padding-top: 16rem;
  }
  .sec05_img {
    top: 35rem;
  }
  .sec06_img {
    top: 36rem;
  }
}
@media (max-width: 1190px) {
  .sec01_inner {
    padding: 12rem 0;
  }
  .sec01_inner .left {
    padding-top: 0;
  }
  .left > h1 {
    font-size: 4rem;
    text-align: center;
  }
  .left > p {
    font-size: 1.6rem;
    text-align: center;
  }
  .sec01_inner .btns {
    justify-content: center;
  }
  .apple_btn,
  .google_btn {
    font-size: 1.8rem;
    padding: 1.6rem;
  }
  .clean_btn {
    font-size: 1.8rem;
    padding: 1.2rem 4.7rem;
  }
  .apple_btn > img,
  .google_btn > img,
  .clean_btn > img {
    max-width: 2rem;
  }
  .sec01_img {
    max-width: 50rem;
    width: 100%;
    margin: 2rem auto 0;
    position: static;
  }
  .sec02_inner {
    padding: 12rem 0;
  }
  .sec02_img {
    max-width: 50rem;
    width: 100%;
    margin: 2rem auto 0;
    position: static;
  }
  .sec03_inner {
    padding: 12rem 0;
  }
  .inner_flex {
    flex-direction: column;
    gap: 4rem;
  }
  .inner_flex .right > p {
    font-size: 1.6rem;
    text-align: center;
  }
  .rolling_wrap {
    max-width: 56rem;
    width: 100%;
    margin: 0 auto;
    height: 3rem;
  }
  .rolling_wrap li {
    width: 100%;
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translate(-50%);
    font-size: 2.4rem;
    font-weight: 900;
    color: var(--white-color);
    text-align: center;
  }
  .sec03_img {
    max-width: 50rem;
    width: 100%;
    margin: 4rem auto 0;
    position: static;
  }
  .sec03_img > img {
    max-width: 50rem;
    width: 100%;
  }
  .sec04_inner {
    padding: 12rem 0;
  }
  .left .s_h1 {
    font-size: 3.6rem;
  }
  .sec04_img {
    max-width: 30rem;
    width: 100%;
    margin: 2rem auto 0;
    position: static;
  }
  .sec05_inner {
    padding: 12rem 0;
  }
  .sec05_img {
    max-width: 50rem;
    width: 100%;
    margin: 2rem auto 0;
    position: static;
  }
  .sec05_img > img {
    max-width: 50rem;
    width: 100%;
  }
  .sec06_inner {
    padding: 12rem 0;
  }
  .sec07_inner {
    padding: 15rem 0;
    flex-direction: column;
    gap: 7.6rem;
  }
  .icon_wrap {
    gap: 4rem;
  }
  .icon_wrap > img {
    max-width: 6.8rem;
  }
  .icon_txt_wrap > p {
    font-size: 1.6rem;
  }
  .icon_txt_wrap > h3 {
    font-size: 2.6rem;
  }
}
@media (max-width: 400px) {
  .sec01_inner .btns {
    flex-direction: column;
    gap: 0.8rem;
  }
  .apple_btn,
  .google_btn {
    font-size: 1.6rem;
    padding: 1.2rem;
    width: 100%;
    justify-content: center;
  }
  .clean_btn {
    font-size: 1.6rem;
    padding: 0.8rem;
    width: 100%;
    justify-content: center;
  }
  .left > h1 {
    font-size: 3rem;
  }
  .left > p {
    font-size: 1.4rem;
  }
  .sec01_img {
    margin-top: 1.6rem;
  }
  .sec02_img {
    margin-top: 1.6rem;
  }
  .sec04_img {
    margin-top: 1.6rem;
  }
  .sec05_img {
    margin-top: 1.6rem;
  }
  .left .s_h1 {
    font-size: 2.4rem;
  }
  .sec06_img {
    top: 28rem;
  }
}

/* footer */
footer {
  background: var(--footer-bg);
  width: 100%;
  padding: 7rem 0;
}
.footer_logo {
  flex-wrap: wrap;
  align-items: center;
  filter: grayscale(100%);
  opacity: 0.6;
}
.busan-logo,
.venture-logo {
  width: 82px;
  height: auto;
  margin-left: 20px;
}
.footer_menu {
  gap: 3.2rem;
  font-size: 1.6rem;
  font-weight: 700;
  color: var(--footer-text);
}
.footer_menu li {
  transition: 0.3s;
  cursor: pointer;
}
.footer_menu li:hover {
  color: var(--text-color);
}
.footer_txt li {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.3;
  color: var(--footer-text);
}
.sns_wrap {
  gap: 2.4rem;
}
.sns_wrap li {
  cursor: pointer;
}

/* 모바일 환경의 줄바꿈 대응 */
br.break-m,
br.break-s {
  display: none;
}
.d-only {
  display: inline-block;
}

@media (max-width: 1400px) {
  footer {
    padding: 7rem 2rem;
  }
}
@media (max-width: 900px) {
  footer {
    padding: 6rem 2rem;
  }
  .footer_inner {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 4rem;
  }
  .footer_logo {
    margin: 0 auto 2rem;
    align-items: center;
    justify-content: center;
  }
  .footer_menu {
    justify-content: center;
  }
  .footer_txt > li {
    text-align: center;
  }
}
/* 모바일 환경 줄바꿈 대응 */
@media (max-width: 580px) {
  br.break-m {
    display: block;
  }
  .d-only {
    display: none;
  }
}

/* back to top */
#button {
  display: inline-block;
  background-color: var(--white-color);
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 50%;
  position: fixed;
  bottom: 30px;
  right: 30px;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
}
#button::after {
  content: "\f077";
  font-family: FontAwesome;
  font-weight: bold;
  font-style: normal;
  font-size: 2em;
  line-height: 50px;
  color: var(--main-color);
}
#button:hover {
  cursor: pointer;
  background: var(--footer-bg);
}
#button:active {
  background: var(--footer-bg);
}
#button.show {
  opacity: 1;
  visibility: visible;
}
