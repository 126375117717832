.frame {
  display: flex;
  justify-content: center;
  justify-self: center;
  align-self: center;
  align-items: center;
  width: auto;
  height: auto;
  background: linear-gradient(145deg, #ffffff, #e6e6e6);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  padding: 0.7em;
}

.content {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.certificate-title {
  margin-top: 3em;
  font-size: calc(16px + 200%);
}

@media all and (max-width: 640px) {
  .frame {
    padding: 0.5em;
  }
  .certificate-title {
    margin-top: 3em;
    font-size: calc(16px + 100%);
  }
}
