#contact-form {
  display: flex;
  justify-content: center;

  background: linear-gradient(#f0f2f5, #f0f0f0);
}
#contact-form .contact-section-in {
  width: 1400px;
  text-align: center;
  padding-top: 10vh;
}

#contact-form .contact-section-in .title_box .title h1 {
  font-size: calc(16px + 200%);
  font-weight: 700;
  margin: 2vh 0px;
}
#contact-form .contact-section-in .title_box .title h2 {
  font-size: calc(16px + 80%);
  font-weight: 400;
}
#contact-form .contact-section-in .contact_from_box {
  text-align: left;
}
#contact-form .contact-section-in .contact_from_box .text01 {
  margin: 0.2vh 0vh;
}
#contact-form .contact-section-in .contact_from_box li {
  padding: 0.5vh 0vh;
}
#contact-form .contact-section-in .contact_from_box li:nth-child(odd) {
  font-size: calc(16px + 20%);
  font-weight: 500;
  padding: 2vh 0vh 1vh 0.5vh;
}
#contact-form .contact-section-in .contact_from_box li:nth-child(even) {
  font-size: calc(16px + 0%);
  font-weight: 400;
  color: #666;
  padding: 0vh 0vh 2vh 0vh;
  border-bottom: 1px solid #ccc;
}

#contact-form .contact-section-in .contact_from_box .button {
  width: 100%;
  background-color: #000000;
  padding: 1vh 3vh;
  margin: 1vh 0vh;
  color: #fff;
  font-size: calc(16px + 40%);
  font-weight: 700;
}
/* input 스타일 */
input[type="number"],
input[type="text"],
input[type="password"],
input[type="date"],
textarea {
  width: 100%;
  height: 52px;
  line-height: 1;
  padding: 10px 14px;
  font-size: 18px;
  font-weight: 400;
  color: #333;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  box-sizing: border-box;
}

textarea {
  height: 300px;
  resize: none;
}

input::placeholder {
  font-size: 18px;
  color: #8d8d8d;
  opacity: 1;
}

input[type="text"].wrong {
  border: 1px solid #da2128;
}

/* button 스타일 */
button {
  background: inherit;
  border: none;
  box-shadow: none;
  border-radius: 10px;
  padding: 0;
  overflow: visible;
  cursor: pointer;
}
/* button 스타일 */

/* check 스타일 */
.check {
  display: block;
}

.check input[type="checkbox"] {
  display: none;
}

.check input[type="checkbox"] + label {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #5a5a5a;
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  cursor: pointer;
}

.check:after {
  display: block;
  clear: both;
  content: "";
}

.check input[type="checkbox"] + label span {
  position: absolute;
  top: -6px;
  left: 30px;
  display: block;
  font-size: 16px;
  color: #5a5a5a;
}

select::-ms-expand {
  display: none;
}

/* textarea 스타일 */
.textbox {
  width: 100%;
  height: 110px;
  padding: 16px;
  font-size: 18px;
  line-height: 1.4;
  color: #000;
  border: solid 1px #dfdfdf;
  border-radius: 4px;
  resize: vertical;
  vertical-align: middle;
}

textarea::placeholder {
  font-family: inherit;
  font-size: 18px;
  line-height: 1.3;
  color: #8d8d8d;
  opacity: 1;
}

/*폼 공통*/

#agreement {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

div:where(.swal2-container) div:where(.swal2-html-container) {
  font-size: 1em !important;
}

div:where(.swal2-container) div:where(.swal2-popup) {
  font-size: 1.6em !important;
}

div:where(.swal2-container) button:where(.swal2-styled):where(.swal2-confirm) {
  font-size: 1em !important;
}
